
import { defineComponent } from 'vue';
import { Book, Lesson, Translation, errorPrepend } from '@/models';
import BookSpecifics from '@/components/admin/BookSpecifics.vue';
import LessonList from '@/components/common/LessonList.vue';
import { lessonMixin } from '@/mixins/lessonMixin';
import { showError } from '@/sweetalert2/templates.ts';
import CsvModal from '@/components/teacher/CsvModal.vue';
import BookCover from '@/components/admin/BookCover.vue';
import AddLessonModal from '@/components/teacher/AddLessonModal.vue';
import { RouteLocationNormalized } from 'vue-router';

export default defineComponent({
  name: 'BookDetails',
  components: { BookCover, CsvModal, LessonList, BookSpecifics, AddLessonModal },
  mixins: [lessonMixin],
  data () {
    return {
      book: {} as Book,
      isNewBook: false,
      lessons: [] as Lesson[]
    };
  },
  async mounted () {
    this.$watch(
      () => this.$route,
      (newVal: RouteLocationNormalized) => {
        if (newVal.fullPath.match(/(?<!redirect=)\/book\//)) {
          this.fetchData();
        }
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    saveChanges (book: Book) {
      book.cover = this.book.cover;
      if (this.isNewBook) {
        this.createNewBook(book);
      } else {
        this.updateCurrentBook(book);
      }
    },
    async addLesson ($event: { lessonName: string, translations: string[][] }) {
      let data = null;
      try {
        data = await Book.addLesson(this.book, $event.lessonName);
      } catch (e) {
        await showError(this.$t("messages.no lesson add") + errorPrepend());
        return;
      }
      await this.loadLessons(this.book.id);
      if ($event.translations.length !== 0) {
        try {
          const translations = this.formatStringArray($event.translations);
          Translation.updateLessonTranslations(data.id, translations).then();
        } catch (e) {
          await showError(this.$t("messages.no add vocab") + errorPrepend());
        } finally {
          Lesson.downloadAudios(data.id).then().catch();
        }
      }
    },
    async loadLessons (id: number) {
      try {
        this.lessons = await Book.getLessons(id);
        this.lessons = this.lessons.sort((a, b) => a.name.localeCompare(b.name));
      } catch (e) {
        await showError(this.$t("messages.no lessons load") + errorPrepend());
      }
    },
    async createNewBook (book: Book) {
      let createdBook = null;
      try {
        createdBook = await Book.create(book);
        if (book.cover) {
          await Book.updateCover(createdBook as Book, book.cover);
        }
        await this.$router.push(`/book/${createdBook.id}`);
      } catch (e) {
        await showError(this.$t("messages.no book create") + errorPrepend());
      }
    },
    async updateCurrentBook (book: Book) {
      try {
        await Book.update(book);
        if (book.cover) {
          await Book.updateCover(book, book.cover);
        }
      } catch (e) {
        await showError(this.$t("messages.no book change") + errorPrepend());
      }
    },
    async saveMultiple (event: { file?: File, lessonName?: string, translations: string[][] }[]) {
      for (const f of event) {
        const filename = f.file?.name || '';
        const name = f.lessonName ||
          filename.match(/(unit|topic)_?\d+/i)?.[0] ||
          filename.replace(/_/g, ' ').replace(/\..*/, '');
        await this.addLesson({ lessonName: name, translations: f.translations });
      }
    },
    saveOne (event: { file?: File, lessonName?: string, translations: string[][] }) {
      const filename = event.file?.name || '';
      const name = event.lessonName ||
        filename.match(/(unit|topic)_?\d+/i)?.[0] ||
        filename.replace(/_/g, ' ').replace(/\..*/, '');
      this.addLesson({ lessonName: name, translations: event.translations });
    },
    newCover ($event: File) {
      this.book.cover = $event;
      this.book.imageUrl = URL.createObjectURL($event);
    },
    async fetchData () {
      if (this.$route.fullPath.includes('new')) {
        this.isNewBook = true;
        return;
      }
      const id = parseInt(this.$route.params.id.toString()) as number;
      this.isNewBook = false;
      try {
        this.book = await Book.getById(id);
        await this.loadLessons(this.book.id);
      } catch (e) {
        await showError(this.$t("messages.no book load") + errorPrepend());
      }
    }
  }
});
