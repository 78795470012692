import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-29d00938")
const _hoisted_1 = { class: "w-100 d-flex flex-wrap" }
const _hoisted_2 = { class: "container-fluid px-xl-5" }
const _hoisted_3 = { class: "py-5" }
const _hoisted_4 = { class: "row justify-content-center" }
const _hoisted_5 = {
  key: 0,
  class: "w-100 justify-content-center mt-5 d-flex"
}
const _hoisted_6 = {
  key: 1,
  class: "row mt-4"
}
const _hoisted_7 = { class: "col-lg-8 offset-lg-2" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookCover = _resolveComponent("BookCover")!
  const _component_BookSpecifics = _resolveComponent("BookSpecifics")!
  const _component_CsvModal = _resolveComponent("CsvModal")!
  const _component_AddLessonModal = _resolveComponent("AddLessonModal")!
  const _component_LessonList = _resolveComponent("LessonList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BookCover, {
            book: _ctx.book,
            onNewCover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.newCover($event)))
          }, null, 8, ["book"]),
          _createVNode(_component_BookSpecifics, {
            book: _ctx.book,
            onSaveChanges: _ctx.saveChanges,
            isNewBook: _ctx.isNewBook
          }, null, 8, ["book", "onSaveChanges", "isNewBook"])
        ]),
        (!_ctx.isNewBook)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_CsvModal, {
                "button-text": "Mehrere Lektionen erstellen",
                onUploadedTranslations: _ctx.saveMultiple
              }, null, 8, ["onUploadedTranslations"]),
              _createVNode(_component_AddLessonModal, {
                class: "mx-1",
                onAddLesson: _ctx.saveOne
              }, null, 8, ["onAddLesson"])
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isNewBook)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_LessonList, {
                  lessons: _ctx.lessons,
                  onLessonDeleted: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadLessons(_ctx.book.id)))
                }, null, 8, ["lessons"])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}