import { defineComponent } from 'vue';

export const lessonMixin = defineComponent({
  methods: {
    formatStringArray (translations: string[][]): { vocabFrom: string; vocabTo: string }[] {
      const properFormat: {vocabFrom: string, vocabTo: string}[] = [];
      translations.forEach(t => properFormat.push({ vocabFrom: t[0], vocabTo: t[1] }));
      return properFormat;
    }
  }
});
