
import { defineComponent, PropType } from 'vue';
import { Book, CommonError, Language } from '@/models';
import { mapState } from 'vuex';
import { showError } from '@/sweetalert2/templates.ts';

export default defineComponent({
  name: 'BookSpecifics',
  computed: {
    ...mapState({ languages: 'languages' })
  },
  data () {
    return {
      editableBook: { name: '', publisher: '' } as Book
    };
  },
  props: {
    book: {
      type: Object as PropType<Book>,
      required: true
    },
    isNewBook: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    book (newVal: Book) {
      if (newVal) { this.editableBook = newVal; }
    }
  },
  emits: ['saveChanges'],
  methods: {
    async saveChanges () {
      const { name, publisher, languageFrom, languageTo } = this.editableBook;
      if (name.length === 0 || publisher.length === 0 ||
        !this.languages.some((l: Language) => l.name === languageFrom) ||
        !this.languages.some((l: Language) => l.name === languageTo)) {
        await showError(this.$t("messages.fill all fields"));
        return;
      }
      this.$emit('saveChanges', this.editableBook);
    }
  }
});
